<template>
  <v-tooltip :disabled="$vuetify.breakpoint.smAndDown" :bottom="!position || position === 'bottom'" :right="position === 'right'" :left="position === 'left'" :top="position === 'top'" color="#303030">
    <template v-slot:activator="{ on, attrs }">
        <v-icon class="hover" :class="[iconClasses ? iconClasses : 'white--text ml-2', $vuetify.breakpoint.smAndDown ? 'bigMobileButton' : '']" medium v-bind="attrs" v-on="on"
                @click="composeVcard(data)">mdi-card-account-mail
        </v-icon>
    </template>
    <span>Get {{data.title}} as V-Card</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: ['data', 'dataName', 'iconClasses', 'position'],
    data() {
      return {
      }
    },
    methods: {
      composeVcard(data) {
        // console.log(data);
        let vCard = [
          "BEGIN:VCARD",
          "VERSION:3.0",
          "PRODID:-//Apple Inc.//macOS 12.5//EN"
        ];
        for (const [key, value] of Object.entries(data)) {
          // console.log(`${key}: ${value}`);
          switch(key) {
            case 'name':  // 
              vCard.push(`N:;${value};;;`)
              vCard.push(`FN:${value}`)
              break;
            
            case 'web':
              vCard.push(`item1.URL;type=pref:${value.startsWith('http') ? value : `https//${value}`}`)
              vCard.push(`item1.X-ABLabel:_$!<HomePage>!$_`)
              break;
            
            case 'title':  // FIRMA!!!
              vCard.push(`ORG:${value};`)
              break;
            
            case 'resources':
              vCard.push(`NOTE:Resources - ${value} - generated by szenoDB`)
              break;
            
            case 'address':
              if(!value.startsWith('http')) {
                vCard.push(`ADR;type=WORK;type=pref:;;${value};;;;`)
              }
              break;
            
            case 'email':
              vCard.push(`EMAIL;type=INTERNET;type=WORK:${value}`)
              break;
            
            case 'tel':
              vCard.push(`TEL;type=WORK;type=VOICE;type=pref:${value}`)
              break;
            
            /* case 'info':
              vCard.push(`${value}`)
              break; */
          }
        }
        vCard.push("END:VCARD");
        this.proposeDownload(`${data.title}.vcf`, vCard.join("\n"));
      },
      proposeDownload(filename, contents) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(contents));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      }
    }
  }
</script>

<style scoped>
  .hover { opacity: .25 !important;}
  .hover:hover { opacity: 1 !important;}
</style>